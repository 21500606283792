import * as React from "react"
import axios from "axios"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { t } from "i18next"
import i18n from "../i18next"
import { validateEmail } from "../components/utils/contactFunctions"
import * as styles from "../styles/contact.module.scss"
import LoadingSymbol from "../svg/loadingIcon.inline.svg"

const ContactForm = () => {
  const [mailData, setMailData] = React.useState({
    mailSent: false,
    isError: false,
    errorMsg: null,
    budget: "< 3000",
    type: "webdesign",
    firstName: "",
    email: "",
  })

  const [mailNotValid, setMailNotValid] = React.useState(false)
  const [nameNotValid, setNameNotValid] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleFormSubmit = e => {
    e.preventDefault()
    let rtrn = false

    if (!validateEmail(mailData.email)) {
      setMailNotValid(true)
      rtrn = true
    } else {
      setMailNotValid(false)
    }
    if (mailData.firstName.length <= 0) {
      setNameNotValid(true)
      rtrn = true
    } else {
      setNameNotValid(false)
    }

    if (rtrn) return

    setIsLoading(true)

    const responseData = {
      Hello: "Neuer Kontakt von:",
      Name: mailData.firstName,
      Budget: mailData.budget,
      Type: mailData.type,
      Message: mailData.msg,
      Contact: mailData.email,
    }

    axios({
      method: "post",
      url: "https://getform.io/f/cbd0a5b4-9b4f-47eb-a998-764f3ef2722d", //change this to correct endpoint
      data: responseData,
      headers: { Accept: "application/json" },
    })
      .then(result => {
        let temp = { ...mailData }
        temp["mailSent"] = true
        temp["isError"] = false
        setMailData(temp)
        setIsLoading(false)
      })
      .catch(error => {
        setMailData({ errorMsg: error.message, isError: true })
        setIsLoading(false)
      })
  }

  //handle changing fields

  const handleChange = (e, field) => {
    let value = e.target.value
    let updateValue = { ...mailData }
    updateValue[field] = value
    setMailData(updateValue)
  }

  const isGerman = i18n.language === "de" ? true : false

  let content
  if (mailData.mailSent) {
    content = (
      <div className="width50 dark contactsuccess">
        <div className="thankyoumessage">
          <StaticImage
            src="../images/about_seal.png"
            alt={
              isGerman
                ? "Majestätische Robbe, die zufrieden nach oben schaut"
                : "Majestic seal, looking satisfied into the sky"
            }
            width={400}
            height={400}
          />

          <p>{t("wellbeintouch")}</p>
        </div>
      </div>
    )
  } else if (mailData.isError) {
    content = (
      <div className="width50 dark contactsuccess">
        <div className="contactErrorMessage">
          <p>{t("sendmailerror")}</p>
        </div>
      </div>
    )
  } else {
    content = (
      <form className={[styles.contactForm, "contactform"].join(" ")}>
        <ul className="flex">
          <li className="width50">
            <label
              htmlFor="name"
              className={nameNotValid ? styles.notValid : ""}
            >
              {nameNotValid ? t("namenotvalid") : "Name"}
            </label>
            <br></br>
            <input
              type="text"
              id="name"
              name="name"
              value={mailData.firstName}
              onChange={e => handleChange(e, "firstName")}
            />
          </li>
          <li className="width50">
            <label htmlFor="projecttype">{t("contactformtype")}</label>
            <br></br>
            <select
              id="projecttype"
              name="projecttype"
              value={mailData.type}
              onChange={e => handleChange(e, "type")}
            >
              <option value="webdesign">Web Design</option>
              <option value="webdev">Web Development</option>
              <option value="landingpage">{t("contactformtype4")}</option>
              <option value="webApp">{t("contactformtype5")}</option>
              <option value="mobileApp">{t("contactformtype3")}</option>
              <option value="ecommerce">{t("contactformtype6")}</option>
              <option value="else">{t("contactformtype7")}</option>
            </select>
          </li>
          <li className="width50">
            <label className={mailNotValid ? styles.notValid : ""}>
              {mailNotValid ? t("mailnotvalid") : t("email")}
            </label>
            <br></br>
            <input
              type="email"
              id="email"
              name="email"
              value={mailData.email}
              onChange={e => handleChange(e, "email")}
            />
          </li>
          <li className="width50">
            <label>{t("yourbudget")}</label>
            <br></br>
            <select
              id="budget"
              name="budget"
              value={mailData.budget}
              onChange={e => handleChange(e, "budget")}
            >
              <option value="< 3000">{t("yourbudget1")}</option>
              <option value="3000 - 5000">{t("yourbudget2")}</option>
              <option value="5000 - 9000">{t("yourbudget3")}</option>
              <option value="> 9000">{t("yourbudget4")}</option>
            </select>
          </li>
          <li className="width100">
            <label>{t("contactcomment")}</label>
            <br></br>
            <textarea
              name="message"
              value={mailData.msg}
              onChange={e => handleChange(e, "msg")}
            ></textarea>
          </li>
        </ul>
        {isLoading ? (
          <LoadingSymbol className={styles.loadingSymbol} />
        ) : (
          <button
            className="cta-button"
            type="submit"
            onClick={e => handleFormSubmit(e)}
            value="Submit"
          >
            {t("send")}
          </button>
        )}
      </form>
    )
  }

  return <> {content}</>
}

function Contact() {
  const { t, i18n } = useTranslation()
  const isGerman = i18n.language === "de" ? true : false

  return (
    <Layout type="contact" key={i18n.language}>
      <section className="subpage-title-section" id="contact-title-section">
        <div className="contact-container dark">
          <motion.div
            initial={{ y: -90, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay: 0 }}
          >
            <h1 className="subpage-title">
              <span>
                {t("wedloveto")} <i>{t("connect")}</i>
              </span>
            </h1>
          </motion.div>
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay: 0 }}
          >
            <p>{t("contactsub")}</p>
          </motion.div>
        </div>
        <div className="subpage-subtitle">
          <hr></hr>
        </div>
      </section>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay: 0.5 }}
      >
        <section className="contact-form">
          <ContactForm></ContactForm>
        </section>
        <section className="contact-address dark">
          <div className="contact-address-wrapper flex">
            <div className="contact-address-col" id="contact-address-col-1">
              <h5>Studio</h5>
              <span>Vanilla Noir</span>
              <br></br>
              <span>1101 Serapis House</span>
              <br></br>
              <span>28 Goodluck Hope Walk</span>
              <br></br>
              <span>London, E14 0XN, UK</span>
              <br></br>
              <br></br>
              <span className="highlight-color-1 bold">+44 7747 206413</span>
              <br></br>
              <span className="highlight-color-1 bold">
                agency@vanilla-noir.com
              </span>
              <br></br>
            </div>
            <div className="contact-address-col" id="contact-address-col-2">
              <StaticImage
                src="../images/map.jpg"
                alt={
                  isGerman
                    ? "Abbildung von einer Karte, die den Standort von Vanilla Noir zeigt"
                    : "Illustration of a map showing the locaion of Vanilla Noir"
                }
                className="contact-img"
              ></StaticImage>
            </div>
          </div>
        </section>
      </motion.div>
    </Layout>
  )
}

export default Contact

export const Head = () => (
  <Seo
    title="Contact"
    titleDE="Kontakt"
    description="Contact us now and fulfil your dream of your own beautiful web application."
    descriptionDE="Kontaktieren Sie uns noch heute und lassen Sie uns gemeinsam Ihren Traum von Ihrer eigenen Website und App erfüllen."
  />
)
